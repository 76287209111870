/* noto-sans-jp-100 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/noto-sans-jp-v52-japanese-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-200 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/noto-sans-jp-v52-japanese-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-300 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/noto-sans-jp-v52-japanese-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-regular - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/noto-sans-jp-v52-japanese-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-500 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/noto-sans-jp-v52-japanese-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-600 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/noto-sans-jp-v52-japanese-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-700 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/noto-sans-jp-v52-japanese-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-800 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/noto-sans-jp-v52-japanese-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-jp-900 - japanese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/noto-sans-jp-v52-japanese-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
