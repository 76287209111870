@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../assets/fonts/noto-sans-jp.scss";

* {
  scrollbar-width: none;
  /* Firefox 用 */
}

/* Webkit ブラウザ用（Chrome, Safariなど） */
*::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.custom-react-toastify div button {
  min-width: 20px !important;
  border: none !important;
}

.hidden-arrow-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  select::-ms-expand {
    display: none;
  }
}

.custom-date-picker {
  div {
    height: 47.53px;

    fieldset {
      border-color: #e3e3e3 !important;
    }
  }

  p {
    margin-left: 0 !important;
    color: #f05956 !important;
  }
}

.clinic-proposal-custom-ratio {
  .MuiSlider-root {
    color: black !important;
  }

  .MuiSlider-thumb,
  .MuiSlider-rail,
  .MuiSlider-mark {
    background-color: black !important;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-right {
    left: inherit !important;
    right: 0 !important;
  }
}

.date-time-picker-ai {
  .MuiPickersToolbar-root {
    display: none !important;
  }
}

.MuiPickersToolbar-root {
  display: none !important;
}

// for react datePicker
.react-datepicker-wrapper {
  width: 100%;
  border: 1px solid !important;
  border-radius: 4px;
  border-color: rgb(227 227 227/var(--tw-border-opacity)) !important;
  padding: 11px 16px !important;
}

.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

.react-datepicker__day--selected {
  background: #c084fc !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: #c084fc !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding-top: 8px !important;
}